<template>
  <v-dialog
    max-width="50%"
    width="50%"
    v-model="isOpen"
    @keydown.escape="closeDialog"
    v-bind="$attrs"
    scrollable>
    <create-carrier-form
      @close="closeDialog"
      @save="handleContactCreated"
      :entry-point="mixpanelEntryPoint"></create-carrier-form>
  </v-dialog>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { useEventBasedDialog } from '@/composables';
import CreateCarrierForm from './CreateCarrierForm.vue';

export default defineComponent({
  name: 'EventTriggeredCreateCarrierContactDialog',
  components: {
    CreateCarrierForm
  },
  setup() {
    const { isOpen, dialogProps, closeDialog } = useEventBasedDialog({
      eventName: 'open-create-carrier-contact-dialog'
    });

    const mixpanelEntryPoint = computed(() => dialogProps.value?.mixpanelEntryPoint);
    const onContactCreated = computed(() => dialogProps.value?.onContactCreated);

    const handleContactCreated = contact => {
      onContactCreated.value?.(contact);
    };

    return {
      mixpanelEntryPoint,
      isOpen,
      closeDialog,
      handleContactCreated
    };
  }
});
</script>

<style scoped lang="scss">
.org-favorite-checkbox.text--disabled > :deep(.v-input__control > .v-input__slot > .v-label) {
  color: $color-text-disabled !important;
}

.org-favorite-checkbox {
  :deep(.v-label) {
    font-weight: 400 !important;
  }
}
</style>
