import config from '@/config/config';
import routeHelpers from '@satellite/helpers/routes';
import NotFound from '@/components/elements/NotFound.vue';
import { isMobile } from '@satellite/plugins/util';
import { createRouter, createWebHistory } from 'vue-router';

export function getComponentForDevice(mainComponent, mobileComponent) {
  if (isMobile()) {
    return mobileComponent ?? mainComponent;
  }

  return mainComponent;
}

// We're adding error handling to vue router and ignoring navigation duplicated errors
// This is a Vue "warning" telling us they aren't going to rerender the components because to/from paths match
function addCatchToRouterFunc(router, funcName) {
  const originalFunc = router[funcName];
  router[funcName] = async function (...args) {
    try {
      const result = await originalFunc.apply(this, args);
      return result;
    } catch (e) {
      onError(e);
    }
  };
}

// error handler
function onError(e) {
  if (
    e.name !== 'NavigationDuplicated' &&
    !e.message?.toLowerCase()?.includes('navigation cancelled')
  ) {
    throw e;
  }
}

// Routes are located in the individual module folders
// Import all of the module routes files.
async function loadLocalRoutes() {
  const context = import.meta.glob('@/modules/**/routes.js');
  const routes = await Promise.all(
    Object.keys(context).map(async key => {
      const module = await context[key]();
      return module.default;
    })
  );
  return routes;
}

let router;

// Load module routes and register them
async function initializeRouter(store, app) {
  let routes = [
    // Moduleless routes can go here
    {
      path: '/',
      name: 'home',
      props: true,
      meta: {
        requiresAuth: false
      },
      beforeEnter: async (to, from, next) => {
        /**
         * We needed a way to log in a user from the scheduling portal if trying to manage the appointment
         * We pass the authenticated user's token along with a nextRoute as params to the route and use wormhole
         * to log them in here
         */
        const nextRoute = to.query?.nextRoute
          ? JSON.parse(to.query?.nextRoute)
          : { name: 'appointments' };
        const token = to.query?.token;
        if (!token) {
          return next(nextRoute);
        }
        await store.dispatch('Auth/loginAs', token, 'loginPageWithTokenParam');
        next(nextRoute);
      }
    },
    {
      path: '/internal',
      name: 'internalHome',
      redirect: 'users',
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/auth/saml/:accessToken',
      name: 'authSaml',
      beforeEnter: async (to, from, next) => {
        const accessToken = to.params.accessToken;
        await store.dispatch('Auth/loginSaml', accessToken);
        next({
          name: 'home',
          params: {}
        });
      },
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/wormhole/login-as/:accessToken',
      name: 'wormhole.loginAs',
      beforeEnter: async to => {
        const accessToken = to.params.accessToken;
        await store.dispatch('Auth/loginAs', accessToken);
        const routeData = {
          name: to.query.nextRoute ?? 'home',
          query: {}
        };
        if (Object.keys(to.query)?.length > 1) {
          delete to.query.name;
          Object.keys(to.query).forEach(key => {
            routeData.query[key] = to.query[key];
          });
        }
        return routeData;
      }
    },
    /**
     * USED FOR PLAYWRIGHT TEST PLAYGROUND
     * Uncomment this route to allow for ui-component testing via /ui-components url.
     */
    // {
    //   path: '/ui-components',
    //   component: UiComponents,
    //   meta: {
    //     title: 'UI Components',
    //     requiresAuth: false,
    //     public: true,
    //     bypassOrgCheck: true
    //   }
    // },
    {
      path: '/404',
      component: NotFound,
      meta: {
        title: 'Page Not Found 404',
        requiresAuth: false,
        public: true,
        bypassOrgCheck: true
      }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404'
    }
  ];

  const moduleRoutes = await loadLocalRoutes();
  routes = routeHelpers.registerRoutes(routes, moduleRoutes);

  router = createRouter({
    history: createWebHistory(),
    routes: routes
  });

  addCatchToRouterFunc(router, 'push');
  addCatchToRouterFunc(router, 'replace');

  router.beforeEach(async (to, from, next) => {
    if (to.name === 'appointmentDetails') {
      window.location.href = `${config.luna_url}/appointments/${to.params.appointmentId}`;
    }
    if (await routeHelpers.checkDirty(store, from)) {
      return;
    }

    const authRequired = routeHelpers.isAuthRequired(to);

    routeHelpers.setPageTitle(store, to, config.application_name ?? 'Opendock');

    if (!authRequired) {
      routeHelpers.setAppLoadedTrue(store);
      next();
      return;
    }

    if (await routeHelpers.isUserAuthorized(store)) {
      routeHelpers.setAppLoadedTrue(store);

      next();
    } else {
      if (config.mixpanel_token) {
        app.config.globalProperties.mixpanel.api.reset();
      }

      routeHelpers.setAppLoadedTrue(store);
      next({ name: 'login', query: { redirectPath: window.location.pathname } });
    }
  });

  router.beforeResolve(async (to, from, next) => {
    if (routeHelpers.isDisabledForUser(store, to)) {
      next({ name: routeHelpers.getHomepageNameForUser(store) });
      store.$app.notify(`Your user doesn't have access to the ${to.meta.title} page.`, 'error');
    }

    next();
  });

  return router;
}
export default initializeRouter;
