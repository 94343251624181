import { getQueryStringFromOptions, getResponse } from '@satellite/services/serviceUtilities';
import { PREVIEW_TAG } from '@nova/core';

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

export default {
  async getCompanyById(companyId, params = {}, options = {}, requestConfig = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(
      `company/${companyId}?${queryString}`,
      {
        params
      },
      requestConfig
    );
    return getResponse(response, options);
  },
  async getCompanies(params, options = {}) {
    const queryString = getQueryStringFromOptions(options);
    const response = await axios.get(`company?${queryString}`, {
      params: params
    });
    return getResponse(response, options);
  },
  async createCompany(data, options = {}) {
    const response = await axios.post(`company`, data);
    return getResponse(response, options);
  },
  async getPreviewCompany() {
    const companies = await this.getCompanies({
      s: {
        tags: {
          $cont: PREVIEW_TAG
        }
      }
    });
    return companies?.[0] ?? null;
  }
};
