<template>
  <v-app ref="appcontainer" class="lightBg is-relative overflow-clip test-class">
    <div id="app-notifications" :class="{ 'is-mobile': false }">
      <app-notification
        position="bottom center"
        class="mb-2"
        group="bottom-notifications"
        :max="5"></app-notification>
      <app-notification
        position="top right"
        class="mt-2 mr-2"
        group="top-notifications"
        :max="5"></app-notification>
    </div>
    <navigation-drawer
      v-if="!$route.meta.hideSidebar && !shouldRenderMobile"
      :unread-msg-count="unreadMsgCount"
      @supportBtnClick="_openChatWindow"
      :orgRequired="orgRequired"></navigation-drawer>

    <header-mobile v-if="shouldRenderMobile"></header-mobile>

    <v-main :class="kebabCase($route.name)">
      <v-alert type="error" v-if="showDisabledOrgAlert" prominent text>
        You are on View Only mode because your Org is inactive. Contact our team to request
        activation.
      </v-alert>
      <v-container
        fluid
        :class="{
          'viewport-height': orgRequired,
          'px-6 py-4': !$route.meta.fullScreen && !shouldRenderMobile,
          'pa-0 full-height': $route.meta.fullScreen || shouldRenderMobile
        }"
        v-if="$appLoaded">
        <h1 v-if="!orgRequired && !$route.meta.customHeader && !isMobile" class="mb-6">
          {{ $pageTitle || $route.meta.title }}
        </h1>
        <transition name="fade" mode="out-in">
          <div :class="{ 'page-wrapper': !shouldRenderMobile, 'full-height': shouldRenderMobile }">
            <template v-if="!shouldRenderMobile">
              <new-org-welcome v-if="orgRequired"></new-org-welcome>
            </template>

            <not-ready-message v-if="isMobile && !$route.meta.allowMobile"></not-ready-message>

            <router-view
              v-if="!orgRequired && ((isMobile && $route.meta.allowMobile) || !isMobile)"
              :class="{ 'is-mobile-content': shouldRenderMobile }"></router-view>
          </div>
        </transition>
      </v-container>
    </v-main>
    <new-terms :isConfirmationNeeded="isConfirmationNeeded"></new-terms>
    <event-triggered-remove-carrier-contact-dialog />
    <event-triggered-create-carrier-company-dialog />
    <event-triggered-create-carrier-contact-dialog />
  </v-app>
</template>

<script>
import { DateTime } from 'luxon';
import { mapState } from 'vuex';
import config from '@/config/config';

import monitorWebsocketConnectionMixin from '@satellite/components/mixins/monitorWebsocketConnectionMixin';
import { isMobile } from '@satellite/plugins/util';
import { kebabCase } from 'lodash';

function injectBeamerScript() {
  const script = document.createElement('script');
  script.id = 'beamer_script';
  script.type = 'text/javascript';
  script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
  script.defer = 'defer';
  document.head.appendChild(script);
  return script;
}

export default {
  name: 'App',
  mixins: [monitorWebsocketConnectionMixin],
  data() {
    return {
      isChatWindowOpen: false,
      unreadMsgCount: 0,
      isMobile: false,
      deviceAgnosticRoutes: ['login', 'register', 'resetPassword']
    };
  },
  computed: {
    ...mapState('navigation', {
      pageTitle: state => state.pageTitle
    }),
    shouldRenderMobile() {
      return this.isMobile && !this.deviceAgnosticRoutes.includes(this.$route.name);
    },
    orgRequired() {
      let shouldCheckOrg = !this.$route.meta.bypassOrgCheck;
      let hasUser = Boolean(this.$me?.id);
      let notInternalUser = hasUser && !this.novaCore.isInternalUser(this.$me);
      let hasNoOrg = hasUser && !this.$me.orgId;

      return shouldCheckOrg && (!hasUser || (notInternalUser && hasNoOrg));
    },
    showDisabledOrgAlert() {
      return this.$org && Boolean(this.$me?.orgId) && this.$org.isActive === false;
    },
    isInternalUser() {
      return (
        this.novaCore.isInternalUser(this.$me) ||
        sessionStorage.getItem('login_origin') === 'loginAs'
      );
    },
    isConfirmationNeeded() {
      return (
        !!this.$me &&
        !this.isInternalUser &&
        (!this.$me.tcConfirmedAt ||
          DateTime.fromISO(this.$me.tcConfirmedAt) < config.terms_conditions_last_update)
      );
    }
  },
  async mounted() {
    this.isMobile = isMobile();
    if (this.isMobile) {
      document.body.classList.add('is-mobile');
    }
    this.$store.commit('Settings/setSettingsMetadata', this.novaCore.EntitySettings);
    this.$nextTick(() => {
      this.$store.dispatch('Auth/setInitialAccessToken');
    });

    this.$eventHub.$on('heartbeat', payload =>
      this.$store.dispatch('App/verifyNeutronVersion', payload)
    );
  },
  methods: {
    kebabCase,
    _enableLauncherBtn() {
      // Enable the launcher button, initially hidden, because it's the only way to minimize the chat
      const iframeLauncher = document.querySelector('iframe#launcher');
      if (iframeLauncher) {
        iframeLauncher.style.display = 'block';
      }
    },
    _openChatWindow() {
      if (window.zE && window.zE?.activate) {
        window.zE.activate();
        // Make sure the messenger is enabled
        // once we disabled it on some pages (TV Mode)
        this._enableLauncherBtn();
        this.$nextTick(() => {
          window.zE('messenger', 'show');
        });
      } else if (typeof zE === 'function') {
        this._enableLauncherBtn();
        this.$nextTick(() => {
          window.zE('messenger', 'show');
          window.zE('messenger', 'open');
        });
      } else {
        this.notify(
          'We are having trouble connecting to Support Chat. Please try again later.',
          'error'
        );
      }
    },
    _setupZendeskMessenger() {
      const isUsingMessengerApi = typeof window.zE === 'function';
      if (isUsingMessengerApi && Boolean(this.$route?.meta?.hideSidebar) === false) {
        window.zE('messenger', 'close');
        window.zE('messenger', 'hide');
        window.zE('messenger:on', 'unreadMessages', number => {
          this.unreadMsgCount = number;
        });
        window.zE('messenger:on', 'close', () => {
          window.zE('messenger', 'hide');
          this.isChatWindowOpen = false;
        });
      } else if (isUsingMessengerApi) {
        window.zE('messenger', 'show');
      }
    },
    _bindZendeskLoadListener() {
      if (typeof window.zE !== 'undefined') {
        this._setupZendeskMessenger();
      } else {
        window.addEventListener(
          'load',
          () => {
            this._setupZendeskMessenger();
          },
          {
            once: true
          }
        );
      }
    }
  },
  watch: {
    $route(newVal, oldVal) {
      // This is fine because it opens in a new window
      // The main window continues to work fine
      if (this.$route.path === '/appointments/tv') {
        window.beamer_config = {};
        const el = document.getElementById('beamer_script');
        el?.remove();
      }

      if (!this.isMobile) {
        this._bindZendeskLoadListener();
      } else {
        this.$store.commit('App/setMobilePageTitle', this.$route.meta.title);
      }
      const shouldUpdateRoute =
        newVal.query.appointmentId &&
        newVal.query.appointmentId !== oldVal.query?.appointmentId &&
        newVal.query.appointmentId !== this.$route.query.appointmentId;

      if (shouldUpdateRoute) {
        this.handleAppointmentQueryString(this.$route.path, newVal.query.appointmentId);
      }
    },
    $me(newVal) {
      if (
        !this.shouldRenderMobile &&
        !this.novaCore.isInternalUser(this.$me) &&
        !this.novaCore.isLoggedInAsAnotherUser(this.$me) &&
        newVal
      ) {
        window.beamer_config = {
          product_id: import.meta.env.VITE_BEAMER_PRODUCT_ID,
          top: 14,
          right: 20,
          user_id: newVal.id,
          user_firstname: newVal.firstName,
          user_lastname: newVal.lastName,
          user_email: newVal.email,
          user_role: newVal.role,
          user_org_id: newVal.orgId
        };
        injectBeamerScript();
      }
    }
  }
};
</script>

<style lang="scss">
.is-mobile-content {
  margin-top: 75px;
}
.is-mobile {
  .dialog-content {
    padding: 0 !important;
  }

  .mobile-nav-dialog-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
</style>
