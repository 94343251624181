<template>
  <div class="v-navigation-drawer">
    <v-list class="pa-0 mt-3 mobile-blue-bg" density="compact" nav>
      <section v-for="item in navItemsToDisplay" :key="item.link">
        <!-- Top Level Link w/o children -->
        <v-tooltip location="right" :disabled="!isItemDisabled(item)">
          <template v-slot:activator="{ props }">
            <v-list-item
              class="text-white"
              link
              v-bind="props"
              :target="getLinkAttrs(item).target"
              :to="getLinkAttrs(item).to"
              :href="getLinkAttrs(item).href"
              v-if="!item.children"
              :inactive="isItemDisabled(item)"
              :class="{ 'disabled-list-item': isItemDisabled(item) }"
              @click="handleListItemButtonClick(item)">
              <v-list-item-action class="mr-4">
                <v-icon size="medium" :disabled="isItemDisabled(item)" class="text-white">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </template>
          <span v-if="isItemDisabled(item)">{{ makeDisabledTooltipMsg(item) }}</span>
        </v-tooltip>

        <v-list-group
          fluid
          :is-disabled="isItemDisabled(item)"
          :class="{ 'disabled-list-item': isItemDisabled }"
          color="white"
          v-if="item.children"
          :value="item.isOpen"
          :key="item.link"
          :prepend-icon="item.icon">
          <!-- Top Level Link with children -->
          <template #activator>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </template>

          <template v-for="childItem in item.children" :key="childItem.link">
            <!-- IF Grandchildren -->
            <v-list-group
              v-if="childItem.children"
              :key="childItem.link"
              :value="childItem.isOpen"
              fluid
              subgroup>
              <template slot="activator">
                <v-tooltip location="top" :disabled="!isItemDisabled(item)" :key="item.link">
                  <template v-slot:activator="{ props }">
                    <v-list-item
                      v-bind="props"
                      class="nd-second-level"
                      :target="getLinkAttrs(childItem).target"
                      :to="getLinkAttrs(childItem).to"
                      :href="getLinkAttrs(childItem).href">
                      <v-list-item-title>{{ childItem.title }}</v-list-item-title>
                    </v-list-item>
                  </template>
                  <span>{{ makeDisabledTooltipMsg(item) }}</span>
                </v-tooltip>
              </template>

              <!-- Grandchild -->
              <v-tooltip location="top" :disabled="!isItemDisabled(item)" :key="grandchild.link">
                <template v-slot:activator="{ on }">
                  <v-list-item
                    link
                    v-for="grandchild in childItem.children"
                    :key="grandchild.link"
                    :target="getLinkAttrs(grandchild).target"
                    :to="getLinkAttrs(grandchild).to"
                    :href="getLinkAttrs(grandchild).href">
                    <v-list-item-title>{{ grandchild.title }}</v-list-item-title>
                  </v-list-item>
                </template>
                <span>{{ makeDisabledTooltipMsg(item) }}</span>
              </v-tooltip>
            </v-list-group>

            <v-tooltip location="top" :disabled="!isItemDisabled(item)">
              <template v-slot:activator="{ on }">
                <v-list-item
                  :key="childItem.title"
                  :target="getLinkAttrs(childItem).target"
                  :to="getLinkAttrs(childItem).to"
                  :href="getLinkAttrs(childItem).href">
                  <v-list-item-title>{{ childItem.title }}</v-list-item-title>
                </v-list-item>
              </template>
              <span>{{ makeDisabledTooltipMsg(item) }}</span>
            </v-tooltip>
          </template>
        </v-list-group>
      </section>
      <section>
        <v-list-item class="text-white" @click="logout">
          <v-list-item-action class="mr-4">
            <v-icon size="medium" class="text-white">mdi-logout</v-icon>
          </v-list-item-action>
          <v-list-item-title class="nav-item-title">Logout</v-list-item-title>
        </v-list-item>
      </section>
    </v-list>

    <v-footer
      class="nav-footer justify-center text-grey-lighten-1 text-center d-block mobile-blue-bg"
      absolute>
      <secondary-button
        small
        class="mb-4 non-uppercase"
        @click="setRenderMode($root.ENUMS.RenderModeEnum.DESKTOP)">
        <v-icon size="small" class="mr-2">mdi-laptop</v-icon>
        View desktop version
      </secondary-button>
      <div class="d-flex full-width align-center justify-center mb-8">
        <connection-status-indicator></connection-status-indicator>
        <div class="version-number ml-2 is-uppercase">
          Nova v{{ $neutronVersion.major }}.{{ $neutronVersion.minor }}.{{ $neutronVersion.patch }}
          -
          {{ $neutronVersion.commit }}
        </div>
      </div>
    </v-footer>
  </div>
</template>

<script>
import NavigationDrawerBase from '@/components/elements/NavigationDrawerBase.vue';

export default {
  name: 'NavigationDrawerMobile',
  extends: NavigationDrawerBase,
  computed: {
    navItems() {
      return [
        {
          title: 'Appointments',
          link: 'appointments',
          maximumRole: this.novaCore.UserRole.OWNER,
          minimumRole: this.novaCore.UserRole.SPECTATOR,
          icon: 'mdi-calendar'
        },
        {
          title: 'Help',
          link: 'https://community.loadsmart.com/hc/en-us/sections/24987137080851-New-Warehouse-Portal-Opendock-Nova',
          maximumRole: this.novaCore.UserRole.GOD,
          minimumRole: this.novaCore.UserRole.BASE,
          icon: 'mdi-help-box',
          external: true,
          onClick: 'trackViewHelpPageInMixPanel'
        }
      ];
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('Auth/logout');
      window.location.replace('/login');
    }
  }
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  width: 100%;
  height: auto;
  position: relative;
  flex: 1;
}
:deep(.v-list) {
  margin-top: 0 !important;
  flex: 1;
}
:deep(.v-list-item__content) {
  display: flex;
}

.nav-footer {
  flex: 0;
  padding: 25px 5px 5px;
}
</style>
