<template>
  <v-dialog
    eager
    @update:model-value="change"
    :model-value="shouldShow"
    :max-width="width"
    :persistent="persistent"
    scrollable
    @keydown.esc="choose(false)"
    @keydown.enter.prevent="choose(true)">
    <v-card tile>
      <template v-if="deleteConfirmation">
        <div class="d-flex flex-column align-center justify-center my-6">
          <v-icon size="48" color="red">{{ icon }}</v-icon>
          <h2 class="mt-3">{{ title }}</h2>
          <h3 class="text--secondary" v-if="entityName">
            {{ entityName }}
          </h3>
        </div>
        <v-divider></v-divider>
      </template>
      <template v-else>
        <v-toolbar class="px-4" v-if="Boolean(title)" :dark="darkTheme" :color="color" dense flat>
          <v-icon size="large" v-if="Boolean(icon)" start>{{ icon }}</v-icon>
          <v-toolbar-title :class="{ 'white--text': darkTheme }" v-text="title" />
          <template v-if="allowClose">
            <v-spacer></v-spacer>
            <v-btn size="small" icon class="mr-3" @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-toolbar>
      </template>
      <v-card-text class="text-body-1 text-body-1 mt-5">
        <slot v-if="message" name="message"></slot>
        <span v-if="!$slots.message && message" v-html="message"></span>

        <v-alert
          v-if="deleteConfirmation && showDeleteWarning"
          variant="tonal"
          prominent
          border="start"
          color="error"
          class="my-5">
          <v-icon color="error" class="mr-2">mdi-alert</v-icon>
          <span>
            <strong>WARNING!</strong>
            this action
            <strong>CANNOT be undone.</strong>
          </span>
        </v-alert>

        <div v-if="confirmationInputText" class="mt-4">
          <p class="mb-2">
            Type "
            <strong>{{ confirmationInputText }}</strong>
            " to confirm:
          </p>
          <text-field
            type="text"
            variant="outlined"
            @paste="handleConfirmPaste"
            v-model="confirmationTextValue"
            :rules="[rules.confirmTextMatch]"></text-field>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <slot name="custom-actions" :choose="choose">
          <v-spacer />
          <slot name="false-action" :choose="choose">
            <v-btn
              v-if="Boolean(buttonFalseText)"
              :color="buttonFalseColor"
              :variant="buttonFalseFlat ? 'text' : undefined"
              :disabled="loading"
              @click="choose(false)">
              {{ buttonFalseText }}
            </v-btn>
          </slot>
          <slot name="true-action" :choose="choose">
            <v-btn
              v-if="Boolean(buttonTrueText)"
              :color="buttonTrueColor"
              :variant="buttonTrueFlat ? 'text' : undefined"
              :loading="loading"
              :disabled="!isConfirmTextMatched"
              @click="choose(true)">
              {{ deleteConfirmation && !buttonTrueText ? 'YES, DELETE' : buttonTrueText }}
            </v-btn>
          </slot>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    buttonTrueText: {
      type: String,
      default: 'Confirm'
    },
    buttonFalseText: {
      type: String,
      default: 'Nevermind'
    },
    buttonTrueColor: {
      type: String,
      default: 'primary'
    },
    buttonFalseColor: {
      type: String,
      default: 'grey'
    },
    buttonFalseFlat: {
      type: Boolean,
      default: true
    },
    buttonTrueFlat: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'warning'
    },
    icon: {
      type: String,
      default: 'mdi-help-circle-outline'
    },
    message: {
      type: String,
      required: false,
      default: 'Are you sure?'
    },
    persistent: Boolean,
    title: {
      type: String,
      default: 'Confirm'
    },
    width: {
      type: Number,
      default: 600
    },
    confirmationInputText: {
      type: String,
      default: null
    },
    preventConfirmPaste: {
      type: Boolean,
      required: false,
      default: true
    },
    pasteRejectionMsg: {
      type: String,
      required: false,
      default: 'The confirmation field does not support pasting'
    },
    shouldShow: {
      type: Boolean,
      required: false,
      default: true
    },
    isManualMode: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    deleteConfirmation: {
      type: Boolean,
      required: false,
      default: false
    },
    showDeleteWarning: {
      type: Boolean,
      required: false,
      default: true
    },
    entityName: {
      type: String,
      required: false,
      default: null
    },
    darkTheme: {
      type: Boolean,
      required: false,
      default: true
    },
    allowClose: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      value: false,
      confirmationTextValue: '',
      rules: {
        confirmTextMatch: () =>
          this.isConfirmTextMatched || `Please confirm by typing "${this.confirmationInputText}"`
      }
    };
  },
  computed: {
    isConfirmTextMatched() {
      return (
        !this.confirmationInputText ||
        this.confirmationInputText.toLowerCase() === this.confirmationTextValue.toLowerCase()
      );
    }
  },
  methods: {
    choose(value) {
      if (value && !this.isConfirmTextMatched) {
        return;
      }
      this.$emit('result', value);
      this.value = value;

      this.destroyComponent();
    },
    change() {
      this.destroyComponent();
    },
    destroyComponent() {
      if (!this.isManualMode) {
        if (this.$el?.parentNode) {
          this.$el.parentNode.removeChild(this.$el);
        }
      }
    },
    handleConfirmPaste(e) {
      if (this.preventConfirmPaste) {
        e.preventDefault();
        this.notify(this.pasteRejectionMsg, 'error');
      }
    }
  }
};
</script>
