<template>
  <div :class="isFlex ? 'd-flex flex-row align-center mb-4 full-width' : ''">
    <h3 v-if="label" :class="disabled ? 'text--disabled' : ''" class="mr-4">
      {{ label }}:
      <help-icon-tooltip v-if="tooltipText">
        {{ tooltipText }}
      </help-icon-tooltip>
    </h3>
    <v-select
      :outlined="outlined"
      :density="density"
      :flat="flat"
      :varant="solo ? 'solo' : 'none'"
      :prepend-icon="!hideIcon ? prependIcon : ''"
      @update:model-value="updateValue"
      :model-value="modelValue"
      :class="classes"
      class="dock-select"
      :items="sortedDocks"
      hide-details="auto"
      :multiple="multiSelect"
      item-value="id"
      :label="customPersistentLabel ?? placeholder"
      return-object
      :placeholder="$attrs.placeholder"
      :persistent-placeholder="!!$attrs.placeholder"
      :disabled="disabled"
      :clearable="clearable"
      @click:clear="updateValue()"
      ref="dockSelect"
      v-bind="$attrs"
      :required="required"
      :single-line="!customPersistentLabel">
      <template #label>
        <slot name="label"></slot>
        <span v-if="required">
          {{ placeholder }}
          <span class="text-red"><strong>*</strong></span>
        </span>
      </template>

      <template #prepend-item v-if="docks.length && multiSelect">
        <v-list-item
          data-testid="dock-select-toggle-all"
          ripple
          @click="toggleSelectAllItems"
          :title="selectAllLabel">
          <template #prepend>
            <v-list-item-action>
              <v-icon class="mr-2" :color="modelValue.length > 0 ? 'error darken-4' : ''">
                {{ selectAllIcon }}
              </v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>
        <v-divider />
      </template>

      <template #item="{ item, props }">
        <slot name="item" :item="item">
          <v-list-item
            :color="
              multiSelect && modelValue.some(val => val.id === item.value) ? 'primary' : 'default'
            "
            v-bind="props"
            :title="`${getDockName(item.raw)} ${!props.title.isActive ? ' (INACTIVE)' : ''}`">
            <template #prepend="{ isSelected }">
              <v-icon
                class="mr-2"
                :color="isSelected ? 'primary' : 'default'"
                :density="density"
                v-if="displayCheckboxes || multiSelect">
                {{ isSelected ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
              </v-icon>
            </template>
          </v-list-item>
        </slot>
      </template>

      <template #selection="{ item, index }">
        <slot name="selection" :item="item" :index="index" v-if="!!modelValue">
          <div v-if="index === 0" class="text-truncate">
            {{ item.raw.name }}
            <small v-if="item.loadTypeIds?.length === 0">(No load types)</small>
          </div>
          <div
            v-if="filterMode && (index === 1 || modelValue.length === 1)"
            class="text-caption selected-quantity text-right"
            :class="selectionTextData.class">
            {{ selectionTextData.text }}
          </div>
          <div v-else>
            <div v-if="index === 1" class="text-caption ml-2 selected-quantity text-right">
              (+{{ modelValue.filter(dock => !novaCore.isCapacityChild(dock)).length - 1 }}
              others)
            </div>
          </div>
        </slot>
      </template>
    </v-select>
  </div>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import selectAllSelectMixin from '@satellite/components/mixins/selectAllSelectMixin';
import { isArray, isObject } from 'class-validator';

export default {
  mixins: [selectMixin, selectAllSelectMixin],
  props: {
    /**
     * @model
     */
    modelValue: {
      required: true
    },
    /**
     * Available options, filter those on parent
     */
    docks: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    /**
     * Multiple values can be selected
     */
    multiSelect: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Disable the select
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: true
    },
    height: {
      type: String,
      required: false,
      default: '25px'
    },
    isFlex: {
      type: Boolean,
      required: false,
      default: true
    },
    classes: {
      type: String,
      required: false,
      default: 'pt-0'
    },
    tooltipText: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    /*
     Shows the selected items in red when not all choices are selected
     */
    filterMode: {
      type: Boolean,
      default: false,
      required: false
    },
    prependIcon: {
      type: String,
      default: 'mdi-dolly'
    },
    customPersistentLabel: {
      type: String,
      required: false
    },
    hideInactiveDocks: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    displayCheckboxes: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      placeholder: this.multiSelect ? 'Select Docks' : 'Select Dock',
      selectRefKey: 'dockSelect'
    };
  },
  computed: {
    density() {
      return this.dense ? 'compact' : 'default';
    },
    selectableItems() {
      return this.filterSelectableItems(this.docks);
    },
    sortedDocks() {
      const alphabeticalSorted = this.novaCore.sortByCaseInsensitive(
        this.filterDisplayableDocks(this.docks),
        'name'
      );
      return this.novaCore.sortByCaseInsensitive(alphabeticalSorted, 'sortOrder');
    },
    selectedItems() {
      return this.filterSelectableItems(this.modelValue);
    },
    selectionTextData() {
      const selected = this.selectedItems.length;
      const total = this.selectableItems.length;

      return {
        text: `${selected}/${total} Selected`,
        class: selected === total ? 'grey--text' : 'red--text'
      };
    }
  },
  mounted() {
    this.updateValue(this.filterSelectableItems(this.modelValue));
  },
  methods: {
    filterDisplayableDocks(docks) {
      return docks.filter(
        dock => !this.novaCore.isCapacityChild(dock) && (!this.hideInactiveDocks || dock.isActive)
      );
    },
    filterSelectableItems(docks) {
      if (Array.isArray(docks)) {
        return this.filterDisplayableDocks(docks) || [];
      }
      return docks;
    },
    getDockLoadTypeCount(dock) {
      return dock.loadTypeIds.length;
    },
    /**
     * Returns dock name with load type count
     */
    getDockName(dock) {
      // TODO this condition is to fix a console error, but it should be investigated further to find the root cause
      if (!dock?.name) {
        return null;
      }

      const loadTypeCount = this.getDockLoadTypeCount(dock);
      const loadTypeNoun = loadTypeCount !== 1 ? 'load types' : 'load type';

      return loadTypeCount > 0
        ? `${dock.name} (${loadTypeCount} ${loadTypeNoun})`
        : `${dock.name} (No ${loadTypeNoun})`;
    },
    updateValue(docks) {
      if (!docks) {
        docks = this.multiSelect ? [] : {};
      }
      this.$emit('update:modelValue', docks);
    },
    selectParentDockWhenChildIsSelected() {
      let isChild = false;
      let selectedDock = {};

      // Verify if the selected dock (from context) is a child dock (parallel)
      if (isArray(this.modelValue)) {
        isChild = this.modelValue.length > 0 && this.novaCore.isCapacityChild(this.modelValue[0]);
        selectedDock = this.modelValue[0];
      } else if (isObject(this.modelValue)) {
        isChild = this.novaCore.isCapacityChild(this.modelValue);
        selectedDock = this.modelValue;
      }

      if (isChild) {
        // Find the parent dock in the list of docks
        const parentDock = this.docks?.find(dock => dock.id === selectedDock.capacityParentId);

        // Select the parent dock in this case because the child dock is not visible here
        if (parentDock?.id) {
          this.updateValue(this.multiSelect ? [parentDock] : parentDock);
        }
      }
    }
  },
  watch: {
    docks(value) {
      if (value) {
        this.selectParentDockWhenChildIsSelected();
      }
    }
  }
};
</script>

<style scoped>
.selected-quantity {
  width: 150px;
  text-align: right;
}
</style>
