<template>
  <form-base :teleport-target="dialogTeleportTarget">
    <template #form>
      <v-form ref="form" class="pa-4">
        <v-row class="mb-4">
          <v-col>
            <text-field
              :required="true"
              type="text"
              density="compact"
              v-model="loadTypeLocal.name"
              :rules="$validator.rules.required('Load Type Name')"
              label="Name"></text-field>
          </v-col>
          <v-col>
            <v-select
              density="compact"
              v-model="loadTypeLocal.direction"
              :items="loadTypeDirectionOptions"
              required
              :rules="$validator.rules.required('Load Type Direction')"
              label="Direction">
              <template #label>
                Direction
                <span class="text-red"><strong>*</strong></span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col>
            <v-select
              density="compact"
              v-model="loadTypeLocal.operation"
              required
              :rules="$validator.rules.required('Operation')"
              :items="loadTypeOperationOptions"
              label="Operation">
              <template #label>
                Operation
                <strong>*</strong>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-select
              density="compact"
              v-model="loadTypeLocal.equipmentType"
              required
              :rules="$validator.rules.required('Equipment Type')"
              :items="loadTypeEquipmentTypeOptions"
              label="Equipment Type">
              <template #label>
                Equipment Type
                <strong>*</strong>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-select
              density="compact"
              v-model="loadTypeLocal.transportationMode"
              required
              :rules="$validator.rules.required('Transportation Mode')"
              :items="loadTypeTransportationModeOptions"
              label="Transportation Mode">
              <template #label>
                Transportation Mode
                <strong>*</strong>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <auto-expandable-textarea
              v-model="loadTypeLocal.description"
              label="Description"></auto-expandable-textarea>
          </v-col>
        </v-row>
        <load-type-duration v-model="loadTypeLocal.duration_min"></load-type-duration>
        <v-row class="mt-6">
          <v-col>
            <h3 class="mt-6 text-black">Scheduling Portal Availability</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <carrier-scheduling-toggle
              entity="Load Type"
              :disabled="!$rolePermissions.canCreateLoadType"
              v-model="loadTypeLocal.allowCarrierScheduling" />
          </v-col>
        </v-row>
        <template v-if="!omitAdvancedSettings">
          <v-row>
            <v-col>
              <h3 class="mt-6 text-black">Advanced Settings</h3>
            </v-col>
          </v-row>
          <v-row class="mt-6">
            <v-col>
              <settings-expansion-panels
                :entity-key="novaCore.DatabaseEntities.LoadType"
                :entity-parent="
                  warehouse ? novaCore.DatabaseEntities.Warehouse : novaCore.DatabaseEntities.Org
                "
                :should-be-expanded="false"
                @update-settings="updateSettings"
                :entity-settings="loadTypeSettings">
                <template v-slot:append-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-title ripple>Load Type Schedule</v-expansion-panel-title>
                    <v-divider></v-divider>
                    <v-expansion-panel-text>
                      <v-row>
                        <v-col class="mt-6">
                          <time-select-grid
                            :settings-entity="warehouse ?? $org"
                            ref="timeSelect"
                            :toggle-to-edit="false"
                            compact
                            v-model="loadTypeLocal.schedule"
                            :with-copy-from-dock="false"
                            :with-copy-from-warehouse="false"
                            @set-schedule-default="setToDefault(loadTypeLocal)"
                            @set-schedule-closed="clearSchedule(loadTypeLocal)"></time-select-grid>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </template>
              </settings-expansion-panels>
            </v-col>
          </v-row>
        </template>
        <v-row class="my-4" v-else>
          <v-col>
            <v-icon size="18" color="gray darken-3" class="mr-1 mb-1">mdi-information</v-icon>
            <span class="text-grey-darken-3 font-size-medium">
              Other advanced settings can be found on the Load Types page.
            </span>
          </v-col>
        </v-row>
      </v-form>
    </template>

    <template #form-actions>
      <action-group
        @cancel="$emit('close')"
        :confirm-icon="null"
        class="mb-2"
        :cancel-icon="null"
        confirm-label="Save Load Type"
        @confirm="promptForSubmission"></action-group>
    </template>
  </form-base>
</template>

<script>
import customMessageMixin from '@satellite/components/mixins/customMessageMixin';
import scheduleMixin from '@satellite/components/mixins/scheduleMixin';
import { inject } from 'vue';

export default {
  mixins: [scheduleMixin],
  props: {
    warehouse: {
      type: Object,
      required: false
    },
    loadType: {
      type: Object,
      required: false
    },
    omitAdvancedSettings: {
      type: Boolean,
      default: false
    },
    skipDirtyValidation: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadTypeLocal: {},
      loadTypeDirectionOptions: Object.values(this.novaCore.LoadTypeDirection),
      loadTypeOperationOptions: Object.values(this.novaCore.LoadTypeOperation),
      loadTypeEquipmentTypeOptions: Object.values(this.novaCore.LoadTypeEquipmentType),
      loadTypeTransportationModeOptions: Object.values(this.novaCore.LoadTypeTransportationMode),
      formInitialized: false,
      interval: 30
    };
  },
  computed: {
    loadTypeSettings() {
      return this.loadTypeLocal.settings ?? {};
    },
    maxLoadtypeWarning() {
      const maxLoadTypes = this.novaCore.MAX_LOAD_TYPES_PER_WAREHOUSE;
      const isWarehouseLoadType = Boolean(this.warehouse);
      return customMessageMixin.computed.maxLoadTypeLimitWarning(maxLoadTypes, isWarehouseLoadType);
    },
    durationConfirmationChangeBody() {
      return `Are you sure you want to change this load type’s <strong><em>duration</em></strong>? Doing this will not
              change any existing appointment’s duration. This will now be the default duration for any newly created
              appointment after this point.
              <br/><br/>
              <strong>Note:</strong> This will impact all warehouses using this load type.`;
    },
    submitAction() {
      return this.loadType?.id ? 'LoadTypes/updateLoadType' : 'LoadTypes/createLoadType';
    },
    mixpanelEvent() {
      const event = this.loadType?.id ? 'UPDATED' : 'CREATED';
      const scope = this.loadTypeLocal.warehouseId ? 'WAREHOUSE_LOAD_TYPE' : 'LOAD_TYPE';
      return this.mixpanel.events.MODULE[scope][event];
    }
  },
  methods: {
    updateSettings(settings) {
      this.loadTypeLocal.settings = settings;
    },
    getChangedFields() {
      let fields = [];

      const isDifferent = (a, b) =>
        typeof a === 'object' || typeof b === 'object'
          ? JSON.stringify(a) !== JSON.stringify(b)
          : a !== b;

      if (this.loadType) {
        Object.keys(this.loadType).forEach(key => {
          if (isDifferent(this.loadType[key], this.loadTypeLocal[key])) {
            fields.push(key);
          }
        });
      } else {
        Object.keys(this.loadTypeLocal).forEach(key => {
          if (typeof this.loadTypeLocal[key] !== 'undefined' && this.loadTypeLocal[key] !== null) {
            fields.push(key);
          }
        });
      }

      return fields;
    },
    trackMixPanelEvent() {
      const eventData = {
        'Load Type ID': this.loadTypeLocal.id,
        'Load Type Name': this.loadTypeLocal.name,
        'Load Type Direction': this.loadTypeLocal.direction,
        'Load Type Changed Fields': this.getChangedFields(),
        'Org ID': this.$org.id,
        'Org Name': this.$org.name
      };
      if (this.loadTypeLocal.warehouseId) {
        eventData['Warehouse ID'] = this.loadTypeLocal.warehouseId;
        eventData['Warehouse Name'] = this.warehouse.name;
      }
      if (!this.loadType?.id) {
        eventData['Entry Point'] = this.mixpanel.getEntryPoint(this, [
          {
            entryPoint: 'Warehouse Load Types Page',
            component: 'warehouse-load-types-tab'
          },
          {
            entryPoint: 'Load Types Page',
            component: 'load-type-list'
          },
          {
            entryPoint: 'Docks Page',
            component: 'dock-list'
          }
        ]);
      }
      this.mixpanel.track(this.mixpanelEvent, eventData);
    },
    submit() {
      this.$refs.timeSelect?.saveChanges();
      this.$store
        .dispatch(this.submitAction, this.loadTypeLocal)
        .then(response => {
          if (response?.data) {
            this.$emit('saved', response.data);
            this.trackMixPanelEvent();
            this.setDirtyStatus(false);

            this.$emit('close');
          }
        })
        .catch(e => {
          if (e.status === 500) {
            this.notify(`Error when saving loadtype`, 'error');
          }
        });
    },
    async promptForSubmission() {
      if (await this.$isFormValid(this.$refs.form)) {
        if (this.loadtype?.id && this.loadTypeLocal.duration_min !== this.loadType?.duration_min) {
          this.$confirm(this.durationConfirmationChangeBody, { color: 'warning' }).then(
            confirmed => {
              if (confirmed) {
                this.submit();
              }
            }
          );
        } else {
          this.submit();
        }
      }
    }
  },
  watch: {
    loadTypeLocal: {
      handler() {
        if (!this.skipDirtyValidation) {
          this.setDirtyStatus(this.formInitialized);
        }
      },
      deep: true
    }
  },
  beforeMount() {
    this.loadTypeLocal = this.loadType
      ? { ...this.loadType }
      : {
          name: '',
          description: '',
          direction: '',
          duration_min: 15,
          allowCarrierScheduling: true,
          warehouseId: this.warehouse?.id,
          schedule: null
        };
  },
  mounted() {
    setTimeout(() => {
      this.formInitialized = true;
    }, 1000);
  },
  setup() {
    const dialogTeleportTarget = inject('dialogActionsRef');
    return { dialogTeleportTarget };
  }
};
</script>
