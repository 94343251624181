import { getResponse } from '@satellite/services/serviceUtilities';
import { DateTime } from 'luxon';

/**
 * options: { fields: string[], joins: string[], includeMetaData: boolean }
 */

export default {
  async fakeCheckIn(warehouseId, data, options = {}, requestConfig = {}) {
    const response = await axios.put(
      `checkin/public/${warehouseId}/unplanned`,
      data,
      requestConfig
    );

    return getResponse(response, options);
  },
  async detachContainer(assetVisitId, assetContainerId, options = {}, requestConfig = {}) {
    const response = await axios.post(
      `asset-visit/${assetVisitId}/detach/${assetContainerId}`,
      requestConfig
    );

    return getResponse(response, options);
  },
  async attachContainer(assetVisitId, assetContainerId, options = {}, requestConfig = {}) {
    const response = await axios.post(
      `asset-visit/${assetVisitId}/attach/${assetContainerId}`,
      null,
      requestConfig
    );

    return getResponse(response, options);
  },
  async updateAssetContainerStatus(assetContainerId, eventType, options = {}, requestConfig = {}) {
    const response = await axios.post(
      `asset-container/${assetContainerId}/event`,
      { eventType, assetContainerId },
      requestConfig
    );

    return getResponse(response, options);
  },
  async updateAssetVisitStatus(assetVisitId, eventType, options = {}, requestConfig = {}) {
    const response = await axios.post(
      `asset-visit/event`,
      { eventType, assetVisitId },
      requestConfig
    );

    return getResponse(response, options);
  },
  async updateAssetVisit(assetVisitId, data, options = {}, requestConfig = {}) {
    const response = await axios.patch(`asset-visit/${assetVisitId}`, data, requestConfig);

    return getResponse(response, options);
  },
  async getAssetContainersByWarehouseId(warehouseId, params = {}, options = {}) {
    // Get only containers that has no asset visit (dettached)
    const baseParams = {
      s: {
        warehouseId: warehouseId,
        assetVisitId: null
      }
    };

    const response = await axios.get(`asset-container`, { params: { ...baseParams, ...params } });
    return getResponse(response, options);
  },
  async getLatestAssetVisitsByWarehouseId(warehouseId, params = {}, options = {}) {
    const baseParams = {
      s: {
        $and: [
          { warehouseId: warehouseId },
          {
            createDateTime: {
              $gte: DateTime.now().minus({ days: 7 }).toISO()
            }
          }
        ]
      },
      join: [
        'company||id,name,scac',
        'appointment||id,start,end,status,statusTimeline,dockId,userId,refNumber,confirmationNumber',
        'appointment.loadType||id,name,direction',
        'appointment.dock||id,name,doorNumber',
        'assetVisitEvents||id,createDateTime,eventType',
        'assetVisitEvents.assetContainer||id,type,code,createDateTime',
        'assetContainers||id,code,type,craeteDateTime',
        'assetContainers.assetContainerEvents||id,createDateTime,eventType',
        'spotAssignments||id,spotId,createDateTime'
      ]
    };

    const response = await axios.get(`asset-visit`, { params: { ...baseParams, ...params } });
    return getResponse(response, options);
  }
};
