import * as core from '@nova/core';

const entityTermMap = {
  Appointment: 'Appointment',
  AuditLog: 'Audit Log',
  Billing: 'Billing',
  Carrier: 'Carrier Contact',
  Company: 'Company',
  Dock: 'Dock',
  LoadType: 'Load Type',
  Org: 'Organization',
  User: 'User',
  Warehouse: 'Warehouse',
  WarehouseGroup: 'Warehouse Group'
};

const notificationConfigs = {
  success: { icon: 'mdi-check-circle-outline', appClasses: 'success' },
  error: { icon: 'mdi-alert-circle-outline', appClasses: 'error' },
  warning: { icon: 'mdi-alert-circle-outline', appClasses: 'warning' },
  info: { icon: 'mdi-information-outline', appClasses: 'info' }
};

export default {
  getEntityTerm(entity) {
    const entityName = entity?.entity ?? '';
    let entityTerm = entityTermMap[entityName] || '';

    // TODO: This logic is not ideal, as it knows about an specific type
    // of appointment. For the future, use some kind of generic "alias" coming
    // from the backend.
    if (entityTerm && core.isReserve(entity.data)) {
      entityTerm += ' (Reserve)';
    }

    return entityTerm;
  },
  getNotificationConfig(type) {
    return notificationConfigs[type];
  }
};
