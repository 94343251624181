//VUE
import { createApp } from 'vue';

// APP
import App from './App.vue';
import config from './config/config';
import { initStore } from './store';

// Mixins
import localStorageMixin from '@satellite/components/mixins/localStorageMixin';
import mappedGetters from '@/components/mixins/mappedGetters';
import mappedStates from '@/components/mixins/mappedStates';
import utilityMixin from '@/components/mixins/utilityMixin';
import settingsMixin from '@/components/mixins/settingsMixin';

// Plugins
import AxiosPlugin from '@satellite/plugins/AxiosPlugin';
import VueTelInput from 'vue3-tel-input';
import 'vue3-tel-input/dist/vue3-tel-input.css';
import ConfirmDialogPlugin from '@satellite/plugins/ConfirmDialog/ConfirmDialogPlugin';
import SocketPlugin from '@satellite/plugins/SocketPlugin';
import util from '@satellite/plugins/util';
import validation from '@satellite/plugins/Validator';
import initSentry from '@satellite/plugins/sentry';
import vuetify from './plugins/vuetifyPlugin';
import Hotjar from 'vue-hotjar';
import Notifications from '@kyvg/vue3-notification';
import velocity from 'velocity-animate';
import featureFlagPlugin from './plugins/featureFlagPlugin';
import initializeRouter from '@/routes.js';

// Deps
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import * as Core from '@nova/core';
import VueApexCharts from 'vue3-apexcharts';
// import VueMask from 'v-mask';
import moment from 'moment-timezone';

import { RenderModeEnum } from '@/enums';
import warehouseService from '@satellite/services/warehouse-service';
import userService from '@satellite/services/user-service';
import dockService from '@satellite/services/dock-service';
import appointmentService from '@satellite/services/appointment-service';
import warehouseGroupService from '@satellite/services/warehouse-group-service';
import MixpanelPlugin from '@satellite/plugins/MixpanelPlugin.js';

import { useEventHub } from '@/composables/index.js';

const app = createApp(App);

// Init Plugins
window.momentjs = moment;
AxiosPlugin.init('/login', 'Helios', app);

// MODULES
// app.use(VueMask);
app.use(VueApexCharts);
app.use(Notifications, { velocity });
app.use(featureFlagPlugin);

// Vue Global Properties
app.config.globalProperties.util = util;
app.config.globalProperties.novaCore = Core;
app.config.globalProperties.$validator = validation;
app.config.globalProperties.config = config;
app.config.globalProperties.$globalLoading = false;

app.config.globalProperties.notify = utilityMixin.methods.notify;

// Services
app.config.globalProperties.services = {
  warehouse: warehouseService,
  warehouseGroup: warehouseGroupService,
  user: userService,
  dock: dockService,
  appointment: appointmentService
};

// Vue Global Mixins
app.mixin(mappedStates);
app.mixin(mappedGetters);
app.mixin(utilityMixin);
app.mixin(localStorageMixin);
app.mixin(settingsMixin);

app.config.globalProperties.mixpanel = new MixpanelPlugin();
app.config.globalProperties.mixpanel.init();

app.mixin({
  mounted() {
    const localSettings = this.$loadObjectFromLocalStorage('local_settings');
    this.$store.commit('Settings/setLocalSettings', localSettings);
    if (localSettings.logSubspace) {
      this.addSubspaceLoggingEventListener();
    }
    this.ENUMS = {
      RenderModeEnum
    };
  }
});

ConfirmDialogPlugin.init(app);

initStore().then(async store => {
  app.use(store);
  app.config.globalProperties.sockets = new SocketPlugin(app);
  store.$app = app;

  /**
   * Create a bound notify function with access to novaCore,
   * so we can use it in the store, like so: `store.notify('message', 'success')`.
   */
  store.notify = (message, type = 'success', options = {}) => {
    utilityMixin.methods.notify.call(
      {
        novaCore: Core,
        $notify: app.config.globalProperties.$notify
      },
      message,
      type,
      options
    );
  };

  app.use(VueTelInput);

  const routes = await initializeRouter(store, app);
  app.use(routes);

  const eventHub = useEventHub();
  app.config.globalProperties.$eventHub = eventHub;

  initSentry(app, routes, config);

  // Import and use vuetify
  app.use(vuetify);
  app.mount('#app');
});

// Hotjar
if (config.hotjar_site_id) {
  app.use(Hotjar, {
    id: config.hotjar_site_id
  });
}

export default app;
