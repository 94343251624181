<template>
  <div v-bind="{ ...$props, ...$attrs }" class="rich-text-input-container">
    <!-- Label + optional tooltip -->
    <label v-if="label" class="v-label">
      {{ label }}
      <help-icon-tooltip v-if="tooltipText" iconStyleClass="grey darken-2">
        {{ tooltipText }}
      </help-icon-tooltip>
    </label>

    <!-- The CKEditor instance -->
    <ckeditor
      v-if="localEditorConfig"
      :editor="editor"
      :model-value="modelValue"
      :config="localEditorConfig"
      @input="onInput"
      @ready="onReady" />
  </div>
</template>

<script>
/*
  RichTextInput.vue
  Example usage: <rich-text-input v-model="text" label="My Label" />
*/
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { merge } from 'lodash';

// Optional: import or define your tooltip component
// import HelpIconTooltip from '@/components/HelpIconTooltip.vue';

export default {
  name: 'RichTextInput',
  components: {
    Ckeditor
  },
  // If you used app.use(CKEditor) in main.js, you do NOT need to locally register CKEditor.
  // But if you prefer local usage, you can do:
  // components: { ckeditor: CKEditor },
  // For demonstration, we assume you used app.use(CKEditor).
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: null
    },
    editorConfig: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      isInitialized: false,
      localEditorConfig: null
    };
  },
  mounted() {
    // Merge default config + user config
    const defaultConfig = {
      // NOTE: The 'plugins' array in a real CKEditor build is typically determined by that build.
      // This is just an example of how you might override or add config options.
      licenseKey: 'GPL', // TODO: We really need to purchase this and use an official commercial key here
      toolbar: {
        items: ['bold', 'italic', 'underline', 'link', 'undo', 'redo']
      },
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://'
      }
    };
    this.localEditorConfig = merge({}, defaultConfig, this.editorConfig);
  },
  methods: {
    onReady(editorInstance) {
      this.$emit('ready', editorInstance);
      // Example: move toolbar above the editing area
      if (!this.isInitialized) {
        const editable = editorInstance.ui.getEditableElement();
        const toolbarEl = editorInstance.ui.view.toolbar.element;
        editable.parentElement.insertBefore(toolbarEl, editable);
        this.isInitialized = true;
      }
    },
    // We intercept the CKEditor input event and re-emit as 'update:modelValue'
    onInput(newValue) {
      this.$emit('update:modelValue', newValue);
    }
  },
  watch: {
    // If parent sets modelValue to null/undefined, we reset to an empty string
    modelValue(newVal) {
      if (newVal === null || typeof newVal === 'undefined') {
        this.$emit('update:modelValue', '');
      }
    }
  }
};
</script>

<style scoped lang="scss">
.rich-text-input-container {
  .ck-content {
    border: 1px solid #777 !important;
    border-radius: 0 0 5px 5px !important;
    border-top: none !important;
  }

  .ck-toolbar {
    border: 1px solid #777 !important;
    border-radius: 5px 5px 0 0 !important;
  }

  /* Hide the "Powered by CKEditor" branding if desired */
  .ck-powered-by {
    display: none !important;
  }
}
</style>
