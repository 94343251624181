<template>
  <div class="d-flex flex-row align-center mb-4 full-width">
    <h3 v-if="label" class="text--disabled mr-4">{{ label }}:</h3>
    <v-autocomplete
      @update:model-value="updateValue"
      prepend-icon="mdi-domain"
      @update:search="searchOrgs"
      no-filter
      :items="orgs"
      placeholder="Search for an org..."
      :no-data-text="searchStr ? 'No orgs found' : 'Start typing to search'"
      item-title="name"
      density="compact"
      item-value="id"></v-autocomplete>
  </div>
</template>

<script>
import selectMixin from '@satellite/components/mixins/selectMixin';
import { debounce } from 'lodash';
/**
 * Org Select
 * @displayName Org Select
 */
export default {
  mixins: [selectMixin],
  data() {
    return {
      orgs: [],
      searchStr: ''
    };
  },
  methods: {
    updateValue(org) {
      this.$emit('update:model-value', org);
    },
    searchOrgs: debounce(async function (searchStr) {
      this.searchStr = searchStr;
      this.orgs = await this.$store.dispatch('Orgs/getOrgs', {
        s: {
          name: { $contL: searchStr }
        },
        sortBy: ['name'],
        limit: 10
      });
    }, 350)
  }
};
</script>
